import React from "react";
import { IconButton } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TikTokIcon from "../icons/TikTokIcon";

const SocialBar = () => (
  <>
    <IconButton onClick={() => {}} color="inherit">
        <InstagramIcon />
    </IconButton>
    <IconButton onClick={() => {}} color="inherit">
        <FacebookIcon />
    </IconButton>
    <IconButton onClick={() => {}} color="inherit">
        <TwitterIcon />
    </IconButton>
    <IconButton onClick={() => {}} color="inherit">
        <YouTubeIcon />
    </IconButton>
    <IconButton onClick={() => {}} color="inherit">
        <div style={{width: 30}}>
        <TikTokIcon/>
        </div>
    </IconButton>
  </>
);

export default SocialBar;
