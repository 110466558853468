import React from "react";
import { withStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import wrapURL from "../WrapUrl";
import SocialBar from "./SocialBar";

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    textAlign: "left",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  link: {
    color: "#000",
    textDecoration: "none",
  },
  toolbar: {
    backgroundColor: "#2e2e2e"
  },
};

function TopBar(props) {
  const { classes } = props;

  const [anchorNav, setAnchorNav] = React.useState(null)

  const handleOpenNavMenu = (event) => {
    setAnchorNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorNav(null);
  };

  const pages = [
    ["Home", "/"],
    ["About", "/about"],
    ["New Builds", "/new-build"],
    ["Refurbs", "/refurb"],
    ["Healthcare", "/healthcare"],
    ["Commercial", "/commercial"],
    ["Maintenance", "/maintenance"],
    ["Shopfitting", "/shopfitting"],
    ["Gallery", "/gallery"],
    ["Testimonials", "/testimonial"],
    ["Contact", "/contact"]
  ]

  return (
    <div className={classes.root}>
      <div style={{width: "100%", height: "3px", background: "#04d9ff"}} />
      <AppBar position="static">
        <Toolbar className={classes.toolbar} style={{paddingTop: 24, paddingBottom: 24}}>
          <Link to={wrapURL("/")} className={classes.link} style={{lineHeight: 1}}>
            <img src={wrapURL("/logo.jpg")} padding="0" height="72"/>
          </Link>
          <span style={{fontSize: 48, paddingLeft: 8, lineHeight: 1}}><span>Contrafit</span></span>
          <Box sx={{ flexGrow: 1}}></Box>
          <Box sx={{ display: { xs: 'flex' } }}>
            <div>
              <span style={{fontSize: "28px", fontWeight: "bold"}}>03330 065 056</span>
              <br/>
              <SocialBar />
            </div>
          </Box>
        </Toolbar>
      </AppBar>
      <div style={{width: "100%", height: "3px", background: "#04d9ff"}} />
      <AppBar position="static">
          <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }} style={{paddingLeft: 16}}>
            {pages.map(([title, link]) => (
                <Button key={title} component={Link} to={wrapURL(link)} color="inherit"  style={{ textTransform: "none" }}>
                  {title}
                </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 1}}></Box>
          <Box sx={{ display: { xs: 'flex', lg: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', lg: 'none' },
              }}
            >
              {pages.map(([title, link]) => (
                <MenuItem key={title} component={Link} to={wrapURL(link)} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
      </AppBar>
    </div>
  );
}

export default withStyles(styles)(TopBar);
