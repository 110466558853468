import React from "react";
import Header from "../components/Header";

const AboutPage = () => (
  <>
    <Header>
        <h1>About Contrafit</h1>
    </Header>
    <p>
      We welcome the opportunity to develop our relationship with Clients and are delighted to present Contrafit Limited.
    </p>
    
    <Header>
      <h2>Experience and Expertise</h2>
    </Header>
    <p>
      Contrafit Ltd have established a Construction company that has a wide range of specialities from foundations to finishing joinery. Our projects include New Builds, Extensions, Refurbishments, Garage and Loft conversions as well as a wide range of joinery and Bespoke Joinery such as Kitchen Design and fitting. We pride ourselves in delivering a high quality finish and a product that our clients are delighted with.  All of our Electrical and Gas installations come with the appropriate certification. Our experience and expertise in the Construction Industry is at the highest level considering a clients needs always researching and sampling new technologies. Taking the clients project through from plans to finished product all within our company gives us the control to manage the project giving the client one point of contact ensuring clarity and prompt decision making and any additions can be made seamlessly successfully and delivered on time.
    </p>
    
    
    <Header>
      <h2>Best Value</h2>
    </Header>
    <p>
      It is our priority to ensure our clients receive the best Value for the product they want. We are focused on delivering a project within our clients budget and finished to a high standard. Our relationships with best practice clients who promote a quality-driven agenda in their procurement and selection process and are committed to delivering best value through quantitative assessment aimed at achieving an appropriate balance between quality and cost. We provide services across Scotland with a wide range of budgets including Commercial, Industrial, Domestic and Retail. 
    </p>
    
    <Header>
      <h2>Partners of Choice</h2>
    </Header>
    <p>
      We have cultivated a long term partnership with our suppliers to insure best value for our clients. Procurement of materials and material cost has become the industry's biggest challenge and at Contrafit we feel it is a priority to show complete clarity and keep our clients informed and to operate at all times in a spirit of mutual trust and co-operation avoiding damaging disputes and conflict at all costs.
    </p>
    
    
    <Header>
      <h2>Quality, Safety and Environment</h2>
    </Header>
    <p>
    Committed to proper processes and procedures establish a framework for efficient, effective and economic service considering the needs of our clients and workforce. Health, safety and welfare is paramount. Construction is underpinned by the principles of sustainable development to encourage prudent use of natural resources, secure effective protection of our environment and reduce our ecological footprint. 
    </p><p>
    We are committed to the delivery of quality projects right first time, to programme and with cost certainty, and if successful in being appointed as principal contractor for any project will deliver on this commitment.
    </p>
  </>
);

export default AboutPage;
