const root = "" // "/~jjcontract";
const wrapURL = (url) => {
  if (url.startsWith("/")) {
    return `${root}${url}`;
  } else {
    return url;
  }
};

export default wrapURL;
