import React from "react";

const Header = (props) => (
  <div
    style={{
      color: "#fff",
      padding: 0,
      margin: 0,
      marginBottom: "4px"
    }}
  >
    {props.children}
    <div style={{margin: "auto", width: "100px", height: "2px", background: "#04d9ff"}} />
  </div>
);

export default Header;
