import React from "react";
import { Grid } from "@mui/material";
import Header from "../components/Header";
import Review from "../components/Review";

const TestimonialPage = () => {
  const testimonials = [
  ["Cathy", "Bonnybridge", "Delighted with recent work to add new windows to bungalow and brick up existing windows. Work was well project managed and completed to very good standard to achieve required outcome. Skilled workers and all done within timescales agreed. Tidy workers and I would recommend them."],
  ["Chris", "Bearsden", "Very prompt response to the request for a Quotation. Started the job within two days of us accepting the Quote. The job was completed on time by Max and two of his colleagues and was exactly as required. No hesitation in giving this company a recommendation."],
  ["Angela", "Glasgow", "Jim and his team did a great job. Would happily recommend for any building work."],
  ["David", "Lanark", "The turnaround from contacting Jim to job completion was incredibly quick (less than 2 weeks). His team worked long hours to complete the work as soon as possible. Very tidy and efficient work practice. Jim is very helpful and acted on all my requests. Overall, delighted with the finished job and would highly recommend"],
  ["Nelson", "Dumbarton", "What an amazing job these guys done converting my old cloakroom toilet into an ensuite, closing up the original entrance and creating a new opening from the neighbouring room which gave us room to install a double shower and vanity. The standard of work was exceptional and I would have no hesitation recommending them to anyone or hiring them again for any future work. The team were all very professional and respectful of my old victorian house. Much appreciated and thanks again."],
  ["Anne", "Stirling", "Can't believe that the job has been done - we have dreamt about getting this work done for ages and Jim and his team came in and whoosh within no time it was finished! What a nice team of guys, very pleasant, polite and disciplined with their working day. They arrived every morning got on with the job, quick lunch break then back to work. They tidied up after they finished and we are delighted with the job they did for us. Would definately use again and would have no hesitation in recommending. Thanks Jim and team!"],
  ["Gareth", "West End", "Jim and the team worked full time for about a month at our flat and did an excellent job, their work is high quality and they kept me up to date on timings and prices if things needed to change. They handled the whole project for us which included replacing the boiler, fitting a new kitchen and converting the original bathroom into a new bathroom and an ensuite wetroom. The job evolved a little from the original design as is often the case with work in tenement buildings but Jim's team helped to come up with solutions as we went on and I think the final result is better than we had originally planned."],
  ["Victoria", "Cambuslang", "Very happy that we hired Jim and James and their team. They always arrived on time, were polite and very hard-working. I was looking for builders that would be good around our 3 young boys and we couldn't fault these guys. Delighted with the workmanship and the overall standard of everything that they have done for us. A tricky job was completed on budget and in a time frame which suited us. Could not recommend this company highly enough. We are having the guys back to fix a drainage problem and have already passed their details to colleagues and family. We had an excellent team and are very grateful to them for providing us with exactly what we needed. Nothing was too much trouble for them. It was a daunting prospect having so many builders around to quote for the job but this team of builders really put us at ease. Very happy."],
  ["Charles", "Glasgow", "James and his crew did an excellent job on my conversion. They gave an competitive quote an completed the job efficiently and to a high standard. They dealt with any issues that arose during the project promptly and explained everything that needed done clearly and honestly. I am extremely happy with the results and the friendliness and professionalism of James and his tradesmen. I would definitely recommend his services."],
  ["Donald", "Glasgow", "Exceptional service all round. Quick, tidy, well priced and professional. James kept in touch throughout the work and arranged the trades needed to finish the job quickly. All the team tidied up as they went too - first class service. I would definitely recommend to anyone."],
  ["Stephen", "Clarkston", "Jim, James and their team did a great job on our extension and loft conversion. Very professional in their work, helpful and straightforward in their dealings with us, and their knowledge and experience shone through in solving problems that cropped up along the way. Their team and the various tradesmen they work with were always friendly and polite - not only with us, but with neighbours, our young kids etc - and were punctual, hard-working and professional in everything they did. Happy to recommend to anyone."],
  ["Charles", "Renfrew", "Excellent job from start to finish, all the guys were neat and tidy couldn't be more helpful job on time and on budget very pleased, made a big difference now we can sit in the sunshine and relax. Would recommend them to everyone whose looking to get any building work done."],
  ["Graham", "Cambuslang", "Friendly and reliable, James' guys left the place spotless and the quality of work was first class"],
  ["Helen", "Port Glasgow", "Jim was excellent particularly as we couldn’t be there all the time. Kept to budget, timescales and went the extra mile to sort the job completed. Would recommend"],
  ["Colin", "Lanark", "Jim & his team were punctual, professional and did a great job. They kept us up to date with the works progress, provided solutions to unexpected problems and worked long hours to finish the works on time. The wet room, renewal of bathroom dormer and window were of a high standard. They also cut through the floor and installed a through floor lift aperture which the engineer was very happy with & signed off their work. I would have no problem recommending them or having them back for further work on our home."],
  ["Jean", "Erskine", "Jim, James and their team done a fantastic job on our build. Due to Covid some parts were delayed however they kept us informed at every step. Very easy to deal with and trustworthy . All Covid safety restrictions were followed at all times . Recommend as quality workmanship."],
  ["Peter", "Renfrew", "I can’t say enough good things about James and his men he has working for him, they turn up at the allotted time ,very clean working practice by all trades, will give you invaluable information about your project. I wouldn’t hesitate in asking him back to do anything else for me. He’s also very reasonably regarding his pricing. Once again thank you."],
  ["Robin", "Strathaven", "I am pleased with the work Jim and his team completed on my internal works: removal of structural walls, bi fold install, kitchen fit, plastering, electrics, drainage, etc. The 'site' was kept tidy after completion. Communication was clear and open from start to finish. All works where explained well and we always knew what was being worked on and what was next. Building work was approved by the council."],
  ["Alison", "Kilbirnie", "Would highly recommend these builders, they did a lovely job at my house, new wall creating a kitchen diner. New kitchen fitted, sorted out old tiles in the hallway. New bathroom downstairs & new bathroom created upstairs in a previous bedroom. The fittings are lovely and the walls completely tiled. Plastering was done where needed. Also new doors back & front and new radiators in the hall, living room, dining room & kitchen. Also new fuse box & outside lights. It was a lot of work and has been finished to a high standard. They are courteous and kept me informed at every stage. They were very quick to return for minor snagging issues. I know they had some difficulties with the house having uneven floors and they have done a brilliant job of hiding pipe work, new skirting and running pipes through the floor. I am very pleased with the finished work, the house looks modern bright and welcoming and I wouldn’t hesitate in recommending this firm for any work you may want doing 😃"],
  ["Briony", "Mosspark", "Jim, James and his team have just finished my extension and I couldn't be happier! The team always turned up on time, were polite and tidy and kept me up to date with what they were doing at all times. If problems came up with the build they always found a solution and the quality of their work is fantastic. We now have a great extension that we are delighted with and will change the way we live as a family. I would definitely recommend this company."]
]



  return (
  <>
    <Header>
      <h1>Testimonials</h1>
    </Header>
    <Grid container direction="column">
      {testimonials.map(([name,place,review]) => (
        <Review key={`${name}${place}`} name={name} place={place} review={review} rating={null}/>
      ))}
    </Grid>
  </>
)
};

export default TestimonialPage;
