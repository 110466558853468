import React from "react";
import { Typography, TextField, Button, Grid } from "@mui/material";
import Header from "../components/Header";
import SocialBar from "../components/SocialBar";

const ContactPage = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    fetch("https://contrafit.co.uk/contact", {
      method: "POST",
      body: data,
      mode: "no-cors",
    });
  };

  return (
    <>
      <Header>
        <h1>Contact</h1>
      </Header>
      <Grid container direction="column">
        <Grid item>Mon - Fri: 8:00 - 17:00</Grid>
        <Grid item>Sat: 10:00 - 16:00</Grid>
        <Grid item>Sun: Closed</Grid>
        <Grid>&nbsp;</Grid>
        <Grid item>Telephone: 03330 065 056</Grid>
        <Grid item>Email: info@contrafit.co.uk</Grid>
        <Grid item>
          <SocialBar />
        </Grid>
      </Grid>
      {false && (
        <form onSubmit={handleSubmit}>
          <Typography component="h2" variant="headline" gutterBottom>
            Contact
          </Typography>
          <TextField label="Name" fullWidth />
          <TextField label="Email" fullWidth />
          <TextField label="Subject" fullWidth />
          <TextField label="Message" fullWidth multiline />
          <Button type="submit">Send</Button>
        </form>
      )}
    </>
  );
};

export default ContactPage;
