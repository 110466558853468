import React from "react";
import { Grid, Rating } from "@mui/material";

const Review = ({ name, place, review, rating }) => (
  <Grid item textAlign={"left"} style={{marginBottom: 8}}>
    <b style={{ fontSize: 22}}>{name}</b> - {place}
    <br />
    {review}
    <br />
    { rating && <Rating name="read-only" value={rating} precision={0.5} readOnly /> }
  </Grid>
);

export default Review;
