import React from "react";
import { makeStyles } from '@mui/styles';
import { Button, Link, Typography } from "@mui/material";
import wrapURL from '../WrapUrl';

const useStyles = makeStyles((theme) => ({
  banner: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: 'auto',
    maxHeight: 700,
    display: 'block',
    objectFit: 'cover',
    filter: 'brightness(75%)'
  },
  overlay: {
    position: 'absolute',
    top: '25%',
    left: '10%',
    textAlign: 'left',
    color: 'white',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
    maxWidth: '80%',
    overflow: 'wrap',
    textOverflow: 'ellipsis',
  },
}));

const FullWidthBanner = () => {
  const classes = useStyles();

  return (
    <div className={classes.banner}>
      <img
        src={wrapURL("/gallery/extension1.jpg")}
        alt="Banner"
        className={classes.image}
      />
      <div className={classes.overlay}>
        <img src={wrapURL("/logo.jpg")} padding="0" height="64"/>
        <br />
        <span style={{fontSize: 42}}><span>Contrafit</span></span>
        <div style={{width: "50px", height: "3px", background: "#04d9ff"}} />
        <br />
        <p>Contrafit Ltd have established a Construction company that has a wide range of specialities from foundations to finishing joinery. Our projects include New Builds, Extensions, Refurbishments, Garage and Loft conversions as well as a wide range of joinery and Bespoke Joinery such as Kitchen Design and fitting.</p>
        <Button component={Link} to={wrapURL("/contact")} variant="contained" style={{background: "#04d9ff", fontWeight: 'bold'}}>Contact Us</Button>
      </div>
    </div>
  );
};

export default FullWidthBanner;