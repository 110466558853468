import React, { useState } from "react";
import { Card, CardMedia, Grid, Backdrop } from "@mui/material";
import wrapURL from "../WrapUrl";
import Header from "../components/Header";

const GalleryPage = () => {
  let roomImages = [
    "extension1.jpg",
    "refurb1.jpg",
    "room1.jpg",
    "room2.jpg",
    "room3.jpg",
    "room4.jpg",
    "room5.jpg",
    "build1.jpg",
    "build2.jpg",
    "build3.jpg",
    "build4.jpg",
    "build5.jpg",
    "build6.jpg",
    "build7.jpg",
    "build8.jpg",
    "build9.jpg",
    "build10.jpg",
    "build11.jpg",
    "build12.jpg",
    "build13.jpg",
    "build14.jpg",
    "build15.jpg",
    "build16.jpg",
    "build17.jpg",
    "build18.jpg",
    "build19.jpg",
    "build20.jpg",
    "build21.jpg",
    "build22.jpg",
    "build23.jpg",
    "build24.jpg",
    "build25.jpg",
    "build26.jpg",
    "build27.jpg",
    "build28.jpg",
    "build29.jpg",
    "build30.jpg",
    "build31.jpg"
    
  ];

  let healthCareImages = [
    "health1.jpg",
    "health2.jpg",
    "health3.jpg",
    "health4.jpg",
    "health5.jpg",
    "health6.jpg",
    "health7.jpg",
    "health8.jpg",
    "health9.jpg",
    "health10.jpg",
    "health11.jpg",
    "health12.jpg",
    "health13.jpg",
    "health14.jpg",
    "health15.jpg",
    "health16.jpg",
    "health17.jpg",
    "health18.jpg",
    "health19.jpg",
    "health20.jpg",
    "health21.jpg",
    "health22.jpg",
    "health23.jpg",
    "health24.jpg",
    "health25.jpg",
    "health26.jpg",
    "health27.jpg",
    "health28.jpg",
    "health29.jpg",
    "health30.jpg",
    "health31.jpg",
    "health32.jpg"
  ];
  const [selected, setSelected] = useState(null);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!!selected}
        onClick={() => setSelected(null)}
      >
        <img src={selected} />
      </Backdrop>
      <Header>
        <h1>Gallery</h1>
      </Header>
      <Grid container>
        <Grid item xs={12}>
          <Card style={{ padding: 8 }}>
            <Header>
              <h2>Rooms</h2>
            </Header>
            <Grid container>
              {roomImages.map((img) => (
                <Grid item>
                  <Card key={img} style={{ height: 150, width: 150 }}>
                    <CardMedia
                      image={wrapURL(`/gallery/${img}`)}
                      style={{ height: 150, width: 150 }}
                      onClick={() => setSelected(wrapURL(`/gallery/${img}`))}
                    />
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Card>
          <Card style={{ padding: 8 }}>
            <Header>
              <h2>Health Care</h2>
            </Header>
            <Grid container>
              {healthCareImages.map((img) => (
                <Grid item>
                  <Card key={img} style={{ height: 150, width: 150 }}>
                    <CardMedia
                      image={wrapURL(`/gallery/${img}`)}
                      style={{ height: 150, width: 150 }}
                      onClick={() => setSelected(wrapURL(`/gallery/${img}`))}
                    />
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default GalleryPage;
