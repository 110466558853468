import React from "react";
import { withStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import wrapURL from "../WrapUrl";
import SocialBar from "./SocialBar";

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    textAlign: "left",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  link: {
    color: "#FFF",
    textDecoration: "none",
  },
  toolbar: {
    backgroundColor: "#4f4f4f",
  },
};

function TopBar(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Grid container direction="column" spacing="2">
            <Grid item container direction="row" spacing="4">
            <Grid item style={{ paddingTop: 24, paddingBottom: 24}}>
              <img src={wrapURL("/logo.jpg")} padding="0" height="72" style={{verticalAlign: 'middle'}}/>
              <span style={{fontSize: 48, paddingLeft: 8, verticalAlign: 'middle'}}><span>Contrafit</span></span>
            </Grid>
            </Grid>

            <Grid item>
              <p>
                All information on this website is subject to change without
                prior notice!
              </p>
            </Grid>
            <Grid>&nbsp;</Grid>
            <Grid>Telephone: 03330 065 056</Grid>
            
            <Grid item>
              <SocialBar />
            </Grid>
            <Grid>&nbsp;</Grid>
            <Grid>
              <Link to={wrapURL("/privacy")} className={classes.link}>
                <b>Privacy Policy</b>
              </Link>
            </Grid>
            <Grid>&nbsp;</Grid>
            <Grid item>&copy; Contrafit LTD</Grid>
            <Grid>&nbsp;</Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withStyles(styles)(TopBar);
