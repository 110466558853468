import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import TopBar from "./components/TopBar";
import BottomBar from "./components/BottomBar";
import { Alert, createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import HomePage from "./containers/HomePage";
import CommercialPage from "./containers/CommercialPage";
import MaintenancePage from "./containers/MaintenancePage";
import NewBuildPage from "./containers/NewBuildPage";
import RefurbishmentPage from "./containers/RefurbishmentPage";
import ShopfittingPage from "./containers/ShopfittingPage";
import HealthCarePage from "./containers/HealthCarePage";
import GalleryPage from "./containers/GalleryPage";
import ContactPage from "./containers/ContactPage";
import TestimonialPage from "./containers/TestimonialPage";
import wrapURL from "./WrapUrl";
import AboutPage from "./containers/AboutPage";
import PrivacyPage from "./containers/PrivacyPage";

const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#8E8E8E",
    },
    secondary: {
      main: "#615B4B",
    },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#8E8E8E",
    },
    secondary: {
      main: "#615B4B",
    },
  },
});



const styles = {
  content: {
    marginLeft: 20,
    marginRight: 20,
  },
};

const App = () => {
    const [privacyDismissed, setPrivacyDismissed] = useState(false);
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <BrowserRouter>
          <div className="App">
            <Alert severity="warning">Under Construction!</Alert>
            {!privacyDismissed && 
              <Alert severity="info" onClose={() => {setPrivacyDismissed(true)}}>
                This site may use cookies, by continuing, you provide consent! If you don't agree to our use of cookies, please navigate away from this site now.
              </Alert>
            }
            <TopBar />
            <div
              style={{ marginTop: 16, padding: 8 }}
            >
              <Routes>
                <Route path={wrapURL("/about")} element={<AboutPage />} />
                <Route path={wrapURL("/commercial")} element={<CommercialPage />} />
                <Route
                  path={wrapURL("/maintenance")}
                  element={<MaintenancePage />}
                />
                <Route
                  path={wrapURL("/new-build")}
                  element={<NewBuildPage />}
                />
                <Route
                  path={wrapURL("/refurb")}
                  element={<RefurbishmentPage />}
                />
                <Route path={wrapURL("/shopfitting")} element={<ShopfittingPage />} />
                <Route path={wrapURL("/healthcare")} element={<HealthCarePage />} />
                <Route path={wrapURL("/gallery")} element={<GalleryPage />} />
                <Route
                  path={wrapURL("/testimonial")}
                  element={<TestimonialPage />}
                />
                <Route path={wrapURL("/contact")} element={<ContactPage />} />
                <Route path={wrapURL("/privacy")} element={<PrivacyPage />} />
                <Route path={wrapURL("/")} element={<HomePage />} />
              </Routes>
            </div>
          </div>
          <div style={{width: "100%", height: "3px", background: "#04d9ff"}} />
          <BottomBar />
        </BrowserRouter>
      </ThemeProvider>
    );
  }

export default withStyles(styles)(App);
