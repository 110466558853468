import React from "react";
import { Alert } from "@mui/material";
import Header from "../components/Header";

const ShopfittingPage = () => (
  <>
    <Header>
      <h1>Shopfitting</h1>
    </Header>
    <Alert severity="info">Coming Soon!</Alert>
  </>
);

export default ShopfittingPage;