import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import wrapURL from "../WrapUrl";
import { Button, Card, CardActionArea, CardMedia, Grid } from "@mui/material";
import FullWidthBanner from "../components/FullWidthBanner";

const styles = {
  media: {
     height: 0,
     paddingTop: '56.25%', // 16:9
     filter: "brightness(75%)"
  },
  card: {
     position: 'relative',
     width: 150,
     borderRight: '2px solid #04d9ff',
     borderBottom: '2px solid #04d9ff'
  },
  overlay: {
     position: 'absolute',
     bottom: '4px',
     left: '4px',
     right: '4px',
     color: '#ffffff',
     fontWeight: 'bold',
     textAlign: 'center'
  }
}

const navPages = [
  ["New Builds", "/new-build", "/gallery/extension1.jpg"],
  ["Refurbs", "/refurb", "/gallery/extension1.jpg"],
  ["Healthcare", "/healthcare", "/gallery/health2.jpg"],
  ["Commercial", "/commercial", "/gallery/extension1.jpg"],
  ["Maintenance", "/maintenance", "/gallery/extension1.jpg"],
  ["Shopfitting", "/shopfitting", "/gallery/build19.jpg"],
]

const HomePage = () => (
  <>

    <Header>
      <h1>Hello and Welcome</h1>
    </Header>

    <FullWidthBanner />

    <div style={{padding: 32}}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        {navPages.map(([title, link, imageUrl]) => (
            <Grid item key={link}>
            <Card style={styles.card}>
            <CardActionArea component={Link} to={wrapURL(link)}>
            <CardMedia image={wrapURL(imageUrl)} style={styles.media}/>
            <div style={styles.overlay}>
                {title}
            </div>
            </CardActionArea>
            </Card>
            </Grid>
        ))}
      </Grid>
    </div>
  </>
);

export default HomePage;
